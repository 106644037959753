.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}
.last-line::after {
  /*code commented for brevity*/
  animation: cursor-blink 1.5s steps(2) infinite;
}
.last-line::after {
  content: "";
  width: 5px;
  height: 20px;
  background: #ec7fff;
  display: inline-block;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
* {
  scroll-margin-top: 140px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiButton-text {
  text-transform: unset !important;
}

@font-face {
  font-family: 'Friends';
  src: url('../public/fonts/friends-italic-webfont.woff') format('woff');
  src: url('../public/fonts/friends-italic-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Friends';

  src: url('../public/fonts/friends-normal-webfont.woff') format('woff');
  src: url('../public/fonts/friends-normal-webfont.woff2') format('woff2');  
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Friends';
  src: url('../public/fonts/friends-semibolditalic-webfont.woff') format('woff');
  src: url('../public/fonts/friends-semibolditalic-webfont.woff2') format('woff2');  
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Friends';

  src: url('../public/fonts/friends-ultralight-webfont.woff') format('woff');
  src: url('../public/fonts/friends-ultralight-webfont.woff2') format('woff2');  
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Friends';

  src: url('../public/fonts/friends-ultralightitalic-webfont.woff') format('woff');
  src: url('../public/fonts/friends-ultralightitalic-webfont.woff2') format('woff2');  
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Friends';

  src: url('../public/fonts/friends-semibold-webfont.woff') format('woff');
  src: url('../public/fonts/friends-semibold-webfont.woff2') format('woff2');    
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
